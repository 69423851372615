<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4" v-if="userLogIn">
        <MySidebar
          active="helpcenter"
          :user="user.userName"
          :image="user.image"
        />
      </div>
      <div :class="userLogIn ? 'col-lg-9 col-md-8' : 'col-md-12'">
        <div class="left-profile mt-5 pt-4 address-card">
          <div class="header-profile">
            <h2>
              {{ $t("Connect us") }}
            </h2>
          </div>

          <div class="m-1 helpcenter">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item ques1">
                <button
                  class="nav-link active"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                >
                  {{ $t("common questions") }}
                </button>
              </li>
              <li class="nav-item ques2">
                <button
                  class="nav-link btn-call"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                >
                  {{ $t("Connect with us") }}
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="questions tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                tabindex="0"
              >
                <ul class="nav nav-pills mb-3" id="pills-sub" role="tablist">
                  <li
                    class="nav-item"
                    v-for="(item, index) in list"
                    :key="item"
                  >
                    <button
                      class="nav-link"
                      :class="index == 0 ? 'active' : ''"
                      data-bs-toggle="pill"
                      :data-bs-target="'#pills-sub-' + item._id"
                      type="button"
                      @click="toggle(item._id)"
                    >
                      {{ $getByLang(item.name) }}
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-subContent">

                  <div
                    v-for="(item, index) in list"
                    :id="'pills-sub-' + item._id"
                    role="tabpanel"
                    :tabindex="index"
                    :key="item._id"
                  >
                    <div class="accordion-w">
                      <Accordion>
                        <AccordionTab
                          v-for="tab in faqs"
                          :key="tab._id"
                          :header="$getByLang(tab.title)"
                        >
                          <p>{{ $getByLang(tab.body) }}</p>
                        </AccordionTab>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                tabindex="1"
              >
                <!-- start -->

                <form @submit.prevent="save()" method="post">
                  <div class="row g-3">
                    <div class="col-md-4">
                      <label for="name" class="form-label">
                        {{ $t("name") }}
                      </label>
                      <input
                        v-model="body.name"
                        :class="{ 'is-invalid': isInvalid && !body.name }"
                        class="from-taab form-control"
                        id="name"
                        :placeholder="$t('name')"
                      />
                    </div>

                    <div class="col-md-8"></div>

                    <div class="col-md-4">
                      <label for="phone" class="form-label">
                        {{ $t("phone") }}
                      </label>
                      <input
                        v-model="body.phone"
                        :class="{ 'is-invalid': isInvalid && !body.phone }"
                        class="from-taab form-control"
                        id="phone"
                        :placeholder="$t('phone')"
                      />
                    </div>

                    <div class="col-md-8"></div>

                    <div class="col-md-4">
                      <label for="title" class="form-label">
                        {{ $t("problem title") }}
                      </label>
                      <input
                        v-model="body.title"
                        :class="{ 'is-invalid': isInvalid && !body.title }"
                        class="from-taab form-control"
                        id="title"
                        :placeholder="$t('problem title')"
                      />
                    </div>
                    <div class="col-md-8"></div>
                    <div class="col-md-4">
                      <label for="body" class="form-label">
                        {{ $t("How can we help you?") }}
                      </label>
                      <textarea
                        v-model="body.body"
                        :class="{ 'is-invalid': isInvalid && !body.body }"
                        class="from-taab form-control"
                        id="body"
                        :placeholder="$t('Write a description of the problem')"
                        rows="5"
                      />
                    </div>

                    <div class="col-md-8"></div>

                    <div class="col-md-12">
                      <button
                        class="btn-addaddress btn mt-4"
                        type="submit"
                        :disabled="disabledAdd"
                      >
                        {{ $t("send") }}
                      </button>
                    </div>
                  </div>
                </form>
                <!-- end -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    const userLogIn = localStorage.homeHere ? true : false;

    return {
      userLogIn,
      body: {
        name: null,
        phone: null,
        title: null,
        body: null,
      },
      user: {
        userName: null,
        email: null,
        phone: null,
        image: null,
      },
      list: [],
      faqs: [],

      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    save() {
      if (this.body.name && this.body.title && this.body.body&& this.body.phone) {
        this.disabledAdd = true;

        this.$http.post("connectus", this.body).then(
          (res) => {
            this.body = {
              name: null,
              title: null,
              body: null,
            };
            this.disabledAdd = false;
            this.$toast.add({
              severity: "success",
              summary: this.$t("Done Successfully"),
              detail: this.$t("Sent Successfully"),
              life: 3000,
            });
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: "error",
              summary: this.$t("Error"),
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: this.$t("Error"),
          detail: this.$t("Please add the required fields"),
          life: 3000,
        });
      }
    },
    toggle(id) {
      this.faqs = [];
      this.$http
        .post("faqs/search?limit=1000000", {
          tagfaqsId: id,
        })
        .then((res) => {
          console.log("this.faq" , res.data);
          
          this.faqs = res.data.docs;
        });
    },
  },
  created() {
    this.$http.post("users/getUserById", {}).then((res) => {
      this.user = res.data;
      this.body.usersId = this.user._id;
      // this.body.phone = this.user.phone;
    });
    this.$http.get("tagfaqs?limit=1000000").then((res) => {
      this.list = res.data.docs;
      console.log("111111111111111" , this.list);
      
      if (this.list.length > 0) {
        this.toggle(this.list[0]._id);
      }
    });

    if (this.$route.query.to && this.$route.query.to == "contact") {
    }
  },
};
</script>
